import {
  HOME_PHONE_AREA_CODES,
  OTHER_PHONE_AREA_CODES,
  VALID_MOBILE_BROADBAND_NUMBER_CODES,
  VALID_MOBILE_NUMBER_CODES,
} from "./variables";

const isEmpty = (text: string) => typeof text === "undefined" || text === null || text.length === 0;

const isPhoneNumber = (phoneNumber: string) => /^[+]{0,1}\d+$/.test(phoneNumber);

export const validateMobileNumber = (
  value: string
): { isValid: boolean; sanitizedValue: string } => {
  if (isEmpty(value)) {
    return { isValid: false, sanitizedValue: value };
  }

  let sanitizedValue = value.replace(/[-\s+]/g, "");

  if (sanitizedValue.substring(0, 2) === "00") {
    sanitizedValue = sanitizedValue.replace("00", "");
  }

  if (sanitizedValue.substring(0, 2) === "46") {
    sanitizedValue = sanitizedValue.replace("46", "0");
  }

  if (
    sanitizedValue.charAt(0) !== "0" ||
    !isPhoneNumber(sanitizedValue) ||
    sanitizedValue.length < 10 ||
    sanitizedValue.length > 14
  ) {
    return { isValid: false, sanitizedValue };
  }

  const isValid =
    VALID_MOBILE_NUMBER_CODES.indexOf(sanitizedValue.substring(0, 3)) !== -1 ||
    VALID_MOBILE_BROADBAND_NUMBER_CODES.indexOf(sanitizedValue.substring(0, 4)) !== -1;

  return { isValid, sanitizedValue };
};

export const isMobileNumber = (inputValue: string) => {
  let value = inputValue;

  if (value.substring(0, 2) === "00") {
    value = value.replace("00", "");
  }

  if (value.substring(0, 2) === "46") {
    value = value.replace("46", "0");
  }

  return (
    VALID_MOBILE_NUMBER_CODES.indexOf(value.substring(0, 3)) !== -1 ||
    VALID_MOBILE_BROADBAND_NUMBER_CODES.indexOf(value.substring(0, 4)) !== -1
  );
};

export const isValidHomePhoneAreaCodes = (value: string) => {
  for (let i = 2; i <= 4; i++) {
    if (HOME_PHONE_AREA_CODES.indexOf(value.substring(0, i)) > -1) {
      return true;
    }
  }
  return false;
};

const isValidOtherPhoneAreaCodes = (value: string) => {
  for (let i = 3; i < 4; i++) {
    const areaCode = value.substring(0, i);
    if (OTHER_PHONE_AREA_CODES.indexOf(areaCode) > -1) {
      return true;
    }
  }
  return false;
};

export const validateHomePhoneNumber = (value: string) => {
  let sanitizedValue = value;

  if (typeof value !== "undefined") {
    sanitizedValue = sanitizedValue.replace(/[-\s]/g, "");
  }

  if (
    !sanitizedValue ||
    sanitizedValue.substring(0, 1) !== "0" ||
    sanitizedValue.length < 7 ||
    sanitizedValue.length > 15
  ) {
    return false;
  }

  return (
    isPhoneNumber(sanitizedValue) &&
    isValidHomePhoneAreaCodes(sanitizedValue) &&
    !validateMobileNumber(sanitizedValue).isValid
  );
};

export const validatePhoneNumber = (value: string) => {
  let sanitizedValue = value;

  if (typeof value !== "undefined") {
    sanitizedValue = sanitizedValue.replace(/[-\s]/g, "");
  }

  return (
    (isPhoneNumber(sanitizedValue) && isValidOtherPhoneAreaCodes(sanitizedValue)) ||
    validateHomePhoneNumber(sanitizedValue) ||
    validateMobileNumber(sanitizedValue).isValid
  );
};
