export const isValidDate = function (year: number, month: number, day: number) {
  const date = new Date(year, month, day);
  return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
};

export const validateBNumber = (value: string) => /^[Bb][0-9]{9,10}$/.test(value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateTrue = (value: any) => typeof value === "boolean" && value === true;

export const validateEmail = (value: string) =>
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value);

export const validateLongLat = (value: string) =>
  typeof value !== "undefined" && value !== null && value !== "";

export const validateIccNumber = (value: string) => /^[0-9]{5}$/.test(value);

export const validateIntNumber = (value: string) => /^INT[0-9]{4,}|BF[0-9]{10}$/.test(value);

export const validateOrganizationNumber = (value: string) => /^[0-9]{6}[0-9]{4}$/.test(value);

export const validateNameOfPerson = (value: string) =>
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
    value
  );

export const validateC2BUID = (value: string) => /^c2buid:[A-Z0-9]+$/.test(value);

export const validateRegex = (value: string, regexp: string) => {
  const isMatch = value.match(new RegExp(regexp));
  return isMatch && isMatch.length > 0;
};
