export const guideGA4Categories: Record<string, string> = {
  bredband: "broadband",
  mobiltelefoni: "mobile_telephony",
  "fast-telefoni": "fixed_telephony",
  "tv-och-streaming": "tv_and_streaming",
  "appar-och-tjanster": "apps_and_services",
};

export const getCategoryFromPathname = () => {
  if (typeof window !== "undefined") {
    return guideGA4Categories[window.location.pathname.split("/")[2]] || "n/n";
  }

  return "n/n";
};
