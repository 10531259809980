import { B2xContentFactory } from "@telia/b2x-content-factory";

export type ContentModelItem = { id: string; query: string };

export type ContentModel<T> = Record<keyof T, ContentModelItem[] | ContentModelItem>;

const contentFactory = new B2xContentFactory(__API__);

export class ContentFactory {
  public static async createContent<T>(
    contentModel: ContentModel<T>
  ): Promise<{ [K in keyof T]: T[K] }> {
    return contentFactory.createContent(contentModel);
  }
}
