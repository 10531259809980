import { track } from "@telia/b2x-google-analytics";
import { getGAString } from "../get-ga-string";

export const trackFAQExpand = ({ label, category }: { label: string; category: string }) => {
  track("faq_expand", {
    self_service_action: "faq_expand",
    self_service_action_label: getGAString(label),
    self_service_category_main: category,
    self_service_action_status: "unknown",
    self_service_tool_type: "faq",
  });
};
