/* eslint-disable @telia/pii/no-email */
import {
  validateBNumber,
  validateC2BUID,
  validateEmail,
  validateIccNumber,
  validateIntNumber,
  validateLongLat,
  validateNameOfPerson,
  validateOrganizationNumber,
  validateTrue,
} from "./generalValidation";
import {
  isMobileNumber,
  isValidHomePhoneAreaCodes,
  validateHomePhoneNumber,
  validateMobileNumber,
  validatePhoneNumber,
} from "./phoneValidation";
import { validateSocialSecurityNumber, validateTscidNumber } from "./ssnValidation";
import { VALIDATION_ERRORS, ValidationType } from "./variables";

export type { ValidationType } from "./variables";

const hasValidationType = (validationType: ValidationType, validationTypes: ValidationType[]) =>
  validationTypes.indexOf(validationType) > -1;

const sanitizeValidationValue = (inputValue: string, validationTypes: ValidationType[]) => {
  let value = inputValue;

  if (
    !hasValidationType(ValidationType.EMAIL, validationTypes) &&
    !hasValidationType(ValidationType.NAME_OF_PERSON, validationTypes)
  ) {
    value = value.replace(/-/g, "");
  }

  return value.replace(/[\s+]/g, "");
};

export const getPlaceHolderText = (validationTypes: ValidationType[]) => {
  const types = validationTypes.length > 2 ? validationTypes.slice(0, 2) : validationTypes;

  let allTypes = types.join(" / ");
  allTypes = allTypes.replace("SSN", "ÅÅÅÅMMDDNNNN");
  allTypes = allTypes.replace("EMAIL", "mail@telia.com");
  allTypes = allTypes.replace("MOBILE_PHONE_NUMBER", "07xxxxxxxx");
  allTypes = allTypes.replace("B_NUMBER", "Bxxxxxxxxx");
  allTypes = allTypes.replace("TSCID", "70xxxxxxxx");
  allTypes = allTypes.replace("HOME_PHONE_NUMBER", "0xxxxxxxxx");
  allTypes = allTypes.replace("PHONE_NUMBER", "0xxxxxxxxx");
  allTypes = allTypes.replace("ORGANISATION_NUMBER", "NNNNNN-NNNN");
  allTypes = allTypes.replace("INT_NUMBER", "INTxxxxxxxx");
  allTypes = allTypes.replace("NAME_OF_PERSON", "Xxxxxxxxx");
  allTypes = allTypes.replace("ICC_CHECK", "Xxxxx");

  return allTypes;
};

export type ValidationState = {
  valid: boolean;
  validationText: string;
  validationTextType: string;
  validationType?: ValidationType;
  replace?: string;
};

type InputValueTypes = string | boolean;

export const validate = (
  validationTypes: ValidationType[],
  inputValue: InputValueTypes,
  inputOptional: boolean
): ValidationState | null => {
  if (!inputOptional && !inputValue) {
    const validation = {
      valid: false,
      validationText: "Det här fältet är obligatoriskt.",
      validationTextType: "",
    };

    return validation;
  }

  if (!validationTypes.length) {
    return null;
  }

  const value =
    typeof inputValue === "string" ? sanitizeValidationValue(inputValue, validationTypes) : "";

  const placeholder = getPlaceHolderText(validationTypes);

  const validationObject = {
    valid: false,
    validationText: VALIDATION_ERRORS.GENERAL.replace("PLACEHOLDER", placeholder),
    validationTextType: "GENERAL",
    replace: value,
  };

  if (typeof inputValue === "boolean") {
    return validateBoolean(inputValue, validationTypes, placeholder);
  }

  // VALIDATE TRUE
  if (hasValidationType(ValidationType.TRUE, validationTypes)) {
    return {
      valid: validateTrue(value),
      validationText: VALIDATION_ERRORS.TRUE,
      validationTextType: "OTHER",
      validationType: ValidationType.TRUE,
    };
  }

  // VALIDATE B NUMBER
  if (
    hasValidationType(ValidationType.B_NUMBER, validationTypes) &&
    (value.indexOf("b") === 0 || value.indexOf("B") === 0)
  ) {
    return {
      valid: validateBNumber(value.toUpperCase()),
      validationText: VALIDATION_ERRORS.B_NUMBER,
      validationTextType: "OTHER",
      validationType: ValidationType.B_NUMBER,
    };
  }

  // VALIDATE PHONE NUMBER
  if (
    hasValidationType(ValidationType.MOBILE_PHONE_NUMBER, validationTypes) &&
    isMobileNumber(value)
  ) {
    const generalValidationFields = {
      validationTextType: "OTHER",
      validationText: VALIDATION_ERRORS.PHONE_NUMBER,
    };

    const { isValid, sanitizedValue } = validateMobileNumber(value);

    validationObject.replace = value;
    return {
      ...generalValidationFields,
      valid: isValid,
      validationType: ValidationType.MOBILE_PHONE_NUMBER,
      replace: sanitizedValue,
    };
  }

  // VALIDATE EMAIL
  if (hasValidationType(ValidationType.EMAIL, validationTypes)) {
    return {
      valid: validateEmail(value),
      validationText: VALIDATION_ERRORS.EMAIL.replace("PLACEHOLDER", value),
      validationTextType: "OTHER",
      validationType: ValidationType.EMAIL,
    };
  }

  // VALIDATE LONG_LAT
  if (hasValidationType(ValidationType.LONG_LAT, validationTypes)) {
    return {
      ...validationObject,
      valid: validateLongLat(value),
      validationType: ValidationType.LONG_LAT,
    };
  }

  // VALIDATE ICC_CHECK
  if (hasValidationType(ValidationType.ICC_CHECK, validationTypes)) {
    return {
      ...validationObject,
      valid: validateIccNumber(value),
      validationType: ValidationType.ICC_CHECK,
    };
  }

  // VALIDATE HOME_PHONE_NUMBER
  if (
    hasValidationType(ValidationType.HOME_PHONE_NUMBER, validationTypes) &&
    value.indexOf("0") === 0 &&
    isValidHomePhoneAreaCodes(value)
  ) {
    return {
      valid: validateHomePhoneNumber(value),
      validationText: VALIDATION_ERRORS.PHONE_NUMBER,
      validationTextType: "OTHER",
      validationType: ValidationType.HOME_PHONE_NUMBER,
    };
  }

  //   VALIDATE PHONE_NUMBER
  if (hasValidationType(ValidationType.PHONE_NUMBER, validationTypes) && value.indexOf("0") === 0) {
    return {
      valid: validatePhoneNumber(value),
      validationText: VALIDATION_ERRORS.PHONE_NUMBER,
      validationTextType: "OTHER",
      validationType: ValidationType.PHONE_NUMBER,
    };
  }

  //   VALIDATE INT_NUMBER
  if (
    hasValidationType(ValidationType.INT_NUMBER, validationTypes) &&
    (value.indexOf("INT") === 0 || value.indexOf("BF") === 0)
  ) {
    return {
      valid: validateIntNumber(value),
      validationText: VALIDATION_ERRORS.INT_NUMBER,
      validationTextType: "OTHER",
      validationType: ValidationType.INT_NUMBER,
    };
  }

  //   VALIDATE ORGANISATION_NUMBER
  if (hasValidationType(ValidationType.ORGANISATION_NUMBER, validationTypes)) {
    return {
      valid: validateOrganizationNumber(value),
      validationText: VALIDATION_ERRORS.ORGANISATION_NUMBER,
      validationTextType: "OTHER",
      validationType: ValidationType.ORGANISATION_NUMBER,
    };
  }

  // VALIDATE NAME_OF_PERSON
  if (hasValidationType(ValidationType.NAME_OF_PERSON, validationTypes)) {
    return {
      valid: validateNameOfPerson(value),
      validationText: VALIDATION_ERRORS.NAME_OF_PERSON,
      validationTextType: "OTHER",
      validationType: ValidationType.NAME_OF_PERSON,
    };
  }

  if (
    hasValidationType(ValidationType.SSN, validationTypes) &&
    value.length > 0 &&
    /^\d+$/.test(value) &&
    (value.length === 10 ||
      (value.length === 12 &&
        (value.indexOf("18") === 0 || value.indexOf("19") === 0 || value.indexOf("20") === 0)))
  ) {
    const generalFields = {
      valid: validateSocialSecurityNumber(value),
      validationType: ValidationType.SSN,
      validationText: VALIDATION_ERRORS.SSN,
      validationTextType: "OTHER",
    };

    if (!generalFields.valid) {
      if (value.length === 10 && validateSocialSecurityNumber(`19${value}`)) {
        return {
          ...generalFields,
          valid: true,
          replace: `19${value}`,
        };
        // Still SSN
      } else if (validateTscidNumber(value)) {
        return {
          ...generalFields,
          valid: true,
          validationType: ValidationType.TSCID,
        };
      }
    }

    return generalFields;
  }

  // VALIDATE C2BUID
  if (hasValidationType(ValidationType.C2BUID, validationTypes)) {
    return {
      valid: validateC2BUID(value),
      validationText: VALIDATION_ERRORS.C2BUID,
      validationTextType: "OTHER",
      validationType: ValidationType.C2BUID,
    };
  }

  return validationObject;
};

const validateBoolean = (
  value: boolean,
  validationTypes: ValidationType[],
  placeholder: string
): ValidationState => {
  const validationObject = {
    valid: false,
    validationText: VALIDATION_ERRORS.GENERAL.replace("PLACEHOLDER", placeholder),
    validationTextType: "GENERAL",
  };

  if (hasValidationType(ValidationType.TRUE, validationTypes)) {
    return {
      valid: validateTrue(value),
      validationText: VALIDATION_ERRORS.TRUE,
      validationTextType: "OTHER",
      validationType: ValidationType.TRUE,
    };
  } else {
    return validationObject;
  }
};
