import { FieldValues, UseFormClearErrors, UseFormSetValue } from "react-hook-form";

import { validate, ValidationType } from "./validate";

export const validateField = (
  value: string,
  isOptional: boolean,
  validationTypes: ValidationType[],
  setValue: UseFormSetValue<FieldValues>,
  fieldName: string,
  clearErrors: UseFormClearErrors<FieldValues>
) => {
  if (!isOptional && !value) {
    return "Det här fältet är obligatoriskt.";
  }

  const validationResult = validate(validationTypes, value, isOptional);

  if (validationResult && !validationResult.valid) {
    return validationResult.validationText;
  }

  if (validationResult?.replace) {
    setValue(fieldName, validationResult?.replace);
  }

  clearErrors(fieldName);

  return true;
};
