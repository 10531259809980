import { isValidDate } from "./generalValidation";

// The last digit is a checksum, calculated using the Luhn algorithm.
const validateFinalSocialSecurityNumberDigit = (value: string) => {
  let checkSum = 0;
  let n;

  for (let i = 2; i <= 10; i++) {
    n = +value[i];

    if (i % 2 === 0) {
      const nTimesTwo = n * 2;
      checkSum += nTimesTwo % 10;
      if (nTimesTwo >= 10) {
        checkSum++;
      }
    } else {
      checkSum += n;
    }
  }

  return (10 - (checkSum % 10)) % 10 === +value[11];
};

export const validateSocialSecurityNumber = (value: string) => {
  let socialNumber = value.replace(/[-\s]/g, "");

  if (socialNumber.length === 10) {
    socialNumber = `19${socialNumber}`; // Adding longer year version
  }

  const year = socialNumber.substring(0, 4);
  const month = socialNumber.substring(4, 6);
  const day = socialNumber.substring(6, 8);

  const validDate = isValidDate(+year, +month - 1, +day);

  const validLength = value.length === 12;
  const validFinalDigit = validateFinalSocialSecurityNumberDigit(socialNumber);

  return validDate && validLength && validFinalDigit;
};

export const validateTscidNumber = (value: string) => /^(70)[0-9]{8}$/.test(value);
